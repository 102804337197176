import { useCallback, useState } from "react"

export const useToggle = (state = false) => {
  const [isActive, setIsActive] = useState<boolean>(state)

  const close = () => setIsActive(false)
  const open = () => setIsActive(true)

  const toggle = useCallback(() => {
    setIsActive((o) => !o)
  }, [setIsActive])

  return { isActive, toggle, open, close, setIsActive }
}
